import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMobileDetector } from "../../../utils/customHooks";
import {
	FooterSection,
	CompanyInfo,
	TextRow,
	PrivacyPolicy,
	BusinessIdea,
	SocialLinks,
	Copyright,
	PageLinks,
	TextRowLink,
} from "./styles";
import logoSmallImg from "../../../Assets/Images/logoSmall.webp";
import footerArrow from "../../../Assets/Images/Home/footer-arrow.svg";

const DefaultFooter = () => {
	const isMobile = useMobileDetector();

	// temporary scroll up
	const handleScrollUp = (e) => {
		e.preventDefault();

		gsap.to(window, {
			scrollTo: { y: 0, autoKill: false },
			duration: 0.5,
			onComplete: () => {
				ScrollTrigger.refresh(); // Refresh ScrollTrigger after scrolling
			},
		});
	}

	return (
		<FooterSection>
			<div>
				<div>
					<CompanyInfo>
						<img src={logoSmallImg} alt="" />
						{!isMobile && (
							<div>
								<TextRow>Yeraz Residential Community</TextRow>
								<TextRow>4/3 Nikoghayos Adonts Street</TextRow>
								<span>
									<TextRowLink to={""} onClick={handleScrollUp}>
										info@spesna.com{" "}
										<img src={footerArrow} alt={""} />
									</TextRowLink>
								</span>
							</div>
						)}
					</CompanyInfo>
				</div>
				<div>
					<BusinessIdea>
						<div>
							<TextRow>Have a great business idea?</TextRow>
						</div>
						<div>
							<TextRowLink to={"/contact-us"}>
								LETS TALK <img src={footerArrow} alt={""} />
							</TextRowLink>
						</div>
					</BusinessIdea>
					<PageLinks>
						{isMobile ? (
							<>
								<div>
									<span>
										<TextRowLink to={"/"}>Home</TextRowLink>
									</span>
									<span>
										<TextRowLink to={"/services"}>
											Services
										</TextRowLink>
									</span>
									<span>
										<TextRowLink to={"/work"}>
											Work
										</TextRowLink>
									</span>
									<span>
										<TextRowLink to={"/culture"}>
											Culture
										</TextRowLink>
									</span>
								</div>
								<div>
									<span>
										<TextRowLink to={"/contact-us"}>
											Contact
										</TextRowLink>
									</span>
									<PrivacyPolicy>
										<span>
											<TextRowLink to={""}>
												Privacy Policy
											</TextRowLink>
										</span>
										<span>
											<TextRowLink to={""}>
												Terms of Services
											</TextRowLink>
										</span>
									</PrivacyPolicy>
								</div>
							</>
						) : (
							<>
								<span>
									<TextRowLink to={"/services"}>
										Services
									</TextRowLink>
								</span>
								<span>
									<TextRowLink to={"/work"}>Work</TextRowLink>
								</span>
								<span>
									<TextRowLink to={"/culture"}>
										Culture
									</TextRowLink>
								</span>
								<span>
									<TextRowLink to={"/contact-us"}>
										Contact
									</TextRowLink>
								</span>
							</>
						)}
					</PageLinks>
					{!isMobile ? (
						<SocialLinks>
							<TextRowLink to={"https://www.linkedin.com/company/spesna"} target={"_blank"}>LinkedIn</TextRowLink>
							<TextRowLink to={""} onClick={handleScrollUp}>Behance</TextRowLink>
							<TextRowLink to={"https://www.instagram.com/spesna.tech"} target={"_blank"}>Instagram</TextRowLink>
						</SocialLinks>
					) : (
						<CompanyInfo>
							<div>
								<TextRow>Get in touch</TextRow>
								<TextRow>Yeraz Residential Community</TextRow>
								<TextRow>4/3 Nikoghayos Adonts Street</TextRow>
								<span>
									<TextRowLink to={""}>
										info@spesna.com{" "}
										<img src={footerArrow} alt={""} />
									</TextRowLink>
								</span>
							</div>
						</CompanyInfo>
					)}
				</div>
				<div>
					{!isMobile ? (
						<PrivacyPolicy>
							<TextRowLink to={""} onClick={handleScrollUp}>Privacy Policy</TextRowLink>
							<TextRowLink to={""} onClick={handleScrollUp}>Terms of Services</TextRowLink>
						</PrivacyPolicy>
					) : (
						<SocialLinks>
							<TextRowLink to={"https://www.linkedin.com/company/spesna"} target={"_blank"}>LinkedIn</TextRowLink>
							<TextRowLink to={""} onClick={handleScrollUp}>Behance</TextRowLink>
							<TextRowLink to={"https://www.instagram.com/spesna.tech"} target={"_blank"}>Instagram</TextRowLink>
						</SocialLinks>
					)}
				</div>
				<div>
					<Copyright>
						<TextRow>© 2024 Spesna. All rights reserved</TextRow>
					</Copyright>
				</div>
			</div>
		</FooterSection>
	);
};

export default DefaultFooter;
