import AllSuggested from "../../Components/Organisms/Services/AllSuggested";
import Steps from "../../Components/Organisms/Services/Steps";
import HeroSection from "../../Components/Organisms/Services/HeroSection";
import ShareVision from "../../Components/Organisms/Services/ShareVision";
import Snapshot from "../../Components/Organisms/Services/Snapshot";

const ServicesPage = () => {
	return (
		<div>
			<HeroSection />
			<Snapshot />
			<AllSuggested />
			<Steps />
			<ShareVision id="shareVisionSection" />
		</div>
	);
};

export default ServicesPage;
