import HeroSection from "../../Components/Organisms/Culture/HeroSection";
import ExcellenceSection from "../../Components/Organisms/Culture/ExcellenceSection";
import OurDigitalHeaven from "../../Components/Organisms/Culture/OurDigitalHeaven";
import Reputation from "../../Components/Organisms/Home/ReputationSwiper";
import Questions from "../../Components/Organisms/Home/FAQ";
import ServingStartups from "../../Components/Organisms/Culture/ServingStartups";

const CulturePage = () => {
	return (
		<div>
			<HeroSection />
			<OurDigitalHeaven />
			<ExcellenceSection />
			<ServingStartups />
			<Reputation />
			<Questions />
		</div>
	);
};

export default CulturePage;
