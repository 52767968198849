import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuContainer, SidebarContainer, SidebarBody, SectionHeading, SocialLinks } from "./styles";
import logoImg from "../../../Assets/Images/MobileMenu/logo-icon.svg";
import closeBtn from "../../../Assets/Images/MobileMenu/close-btn.svg";

const Sidebar = ({ handleMenuClose }) => {
	let location = useLocation();
	const sectionRef = useRef(null);

	useEffect(() => {
		sectionRef.current.style.transform = "translateX(0)";
		sectionRef.current.style.transition = "0.5s ease-in-out";
	}, []);

	return (
		<SidebarContainer ref={sectionRef}>
			<SidebarBody>
				<SectionHeading>
				<Link to={"/"} onClick={handleMenuClose}>
					<img src={logoImg} alt="logo" />
				</Link>
				<img src={closeBtn} alt="close" onClick={handleMenuClose} />
			</SectionHeading>

				<MenuContainer>
					<Link to="/" onClick={handleMenuClose} loc={location.pathname} current={(location.pathname === '/') ? 1 : 0}>
						Home
					</Link>
					<Link to="/services" onClick={handleMenuClose} loc={location.pathname} current={(location.pathname === '/services') ? 1 : 0}>
						Services
					</Link>
					<Link to="/work" onClick={handleMenuClose} loc={location.pathname} current={(location.pathname === '/work') ? 1 : 0}>
						Work
					</Link>
					<Link to="/culture" onClick={handleMenuClose} loc={location.pathname} current={(location.pathname === '/culture') ? 1 : 0}>
						Culture
					</Link>
					<Link to="/contact-us" onClick={handleMenuClose} loc={location.pathname} current={(location.pathname === '/contact-us') ? 1 : 0}>
						Contact
					</Link>
				</MenuContainer>

				<SocialLinks>
					<Link to="https://www.linkedin.com/company/spesna" target={"_blank"}>LinkedIn</Link>
					<Link to="/">Behance</Link>
					<Link to="https://www.instagram.com/spesna.tech" target={"_blank"}>Instagram</Link>
				</SocialLinks>
			</SidebarBody>

		</SidebarContainer>
	);
};

export default Sidebar;
